import type { Swiper as SwiperClass } from 'swiper';
export const useGallery = () => {
    const mainSwiper = ref<SwiperClass>();

    const isBeginning = ref(false);
    const isEnd = ref(false);

    const setControls = () => {
        const swiper = unref(mainSwiper);
        if (swiper) {
            isBeginning.value = !swiper.params.loop && swiper.isBeginning;
            isEnd.value = !swiper.params.loop && swiper.isEnd;
        }
    };

    const swipeLeft = () => {
        if (mainSwiper.value) {
            mainSwiper.value.slidePrev();
        }
    };

    const swipeRight = () => {
        if (mainSwiper.value) {
            mainSwiper.value.slideNext();
        }
    };

    const onSlideFocus = (index: number) => {
        mainSwiper.value?.slideTo(index);
    };

    /**
     * initializes the swiper
     * @param swiper
     */
    const setMainSwiper = (swiper: SwiperClass) => {
        mainSwiper.value = swiper;
        if (mainSwiper.value) {
            mainSwiper.value.on('breakpoint', async () => {
                await wait();
                if (mainSwiper.value) {
                    setControls();
                }
            });
            setControls();
        }
    };

    return {
        mainSwiper,
        isBeginning,
        isEnd,
        setControls,
        swipeLeft,
        swipeRight,
        onSlideFocus,
        setMainSwiper,
    };
};
